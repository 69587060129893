<template>
  <div>
    <div class="my-6 flex items-center justify-between">
      <div>
        <h3 class="font-bold">
          Editar evento > <span class="text-primary">{{ data.nome }}</span>
        </h3>

        <span class="text-sm text-gray-500">Verifique as informações abaixo e clique no botão <b>Salvar</b> para
          confirmar as modificações feitas
        </span>
        <br>
        <span v-if="santaficha" class="text-sm text-gray-500">
          O Id de integração com o Santa Ficha:{{ santaficha.id_santa_ficha }}
        </span>

      </div>
      <div class="flex justify-end">
        <Button @click="edit" color="primary" text="Salvar" id="salvar" />
        <!--<Button @click="SantaFicha()" color="secondary" text="Santa Ficha" class="btn" />-->
      </div>
    </div>

    <div
      class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
      <ul class="flex flex-wrap -mb-px">
        <li class="me-2">
          <a href="#"
            :class="{ 'inline-block p-4 text-pink-800 border-b-2 border-pink-800 rounded-t-lg dark:text-pink-800 dark:border-pink-800': pagina === 0 }"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            @click="setPagina(0)"> Editar Evento</a>
        </li>
        <li class="me-2">
          <a href="#"
            :class="{ 'inline-block p-4 text-pink-800 border-b-2 border-pink-800 rounded-t-lg dark:text-pink-800 dark:border-pink-800': pagina === 1 }"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            @click="setPagina(1)" v-if="integracaoCatraca">Catraca</a>
        </li>
        <li class="me-2">
          <a href="#"
            :class="{ 'inline-block p-4 text-pink-800 border-b-2 border-pink-800 rounded-t-lg dark:text-pink-800 dark:border-pink-800': pagina === 2 }"
            class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
            @click="setPagina(2)">Funcionalidades</a>
        </li>
      </ul>
    </div>


    <div class="card shadow-sm border p-8 bg-white" v-if="pagina === 0">
      <div class="flex lg:flex-row flex-col">
        <div class="w-full p-2">
          <Input type="default" v-model="data.nome" label="Nome" placeholder="Nome do evento" name="nome" />
        </div>
        <div class="w-full p-2">
          <Input type="customDate" :placeholder="'Dia/Mês/Ano'" v-model="data.data" label="Data" :name="'dataEvento'" />
        </div>
        <div class="w-full p-2">
          <Input v-model="data.taxa_sc" type="tax" label="Taxa Santo Cartão (0 a 100%)" placeholder="Taxa"
            :name="'taxa'" />
        </div>
      </div>

      <div class="flex lg:flex-row flex-col">
        <div class="w-full p-2">
          <Input type="default" v-model="data.organizador" label="Organizador" placeholder="Organizador" readonly />
        </div>
        <div class="w-full p-2">
          <Input type="default" v-model="data.checkIn" label="Check In" placeholder="Check In" readonly />
        </div>
      </div>

      <div class="flex lg:flex-row flex-col">
        <div class="w-full p-2">
          <Input type="customDate" :placeholder="'Dia/Mês/Ano'" v-model="data.data_encerramento"
            label="Data de Encerramento da Emissão do Voucher" :name="'DataEncerramentoVoucher'" />
        </div>

        <div class="w-full p-2">
          <Input v-model="data.hora_encerramento" id="Encerramento" type="customTime"
            label="Hora de Encerramento da Emissão do Voucher" placeholder="Hora de Encerramento"
            :name="'HoraEncerramentoVoucher'" />
        </div>
      </div>

      <!-- Configuração de Horario Do Evento -->

      <div class="flex lg:flex-row flex-col">

        <div class="w-full p-2">
          <Input type="customDate" :placeholder="'Dia/Mês/Ano'" id="data_evento_inicio" v-model="data.data_inicio"
            label="Data de Inicio do Evento" :name="'dataInicioEvento'" />
        </div>

        <div class="w-full p-2">
          <Input v-model="data.hora_inicio" id="Inicio" type="customTime" label="Hora de Inicio do Evento"
            placeholder="Hora do Inicio do Evento" :name="'horaInicioEvento'" />
        </div>
      </div>

      <div class="flex lg:flex-row flex-col">
        <div class="w-full p-2">
          <Input type="customDate" :placeholder="'Dia/Mês/Ano'" id="data_evento_fim" v-model="data.data_fim"
            label="Data de Encerramento do Evento" :name="'dataEncerramentoEvento'" />
        </div>

        <div class="w-full p-2">
          <Input v-model="data.hora_fim" id="Fim" type="customTime" label="Hora de Encerramento do Evento"
            placeholder="Hora do Encerramento do Evento" :name="'horaEncerramentoEvento'" />
        </div>
      </div>

      <div class="flex lg:flex-row flex-col">
        <div class="w-full p-2 lg:max-w-sm xl:max-w-lg">
          <label for="regras" class="block relative">
            <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute text-sm">
              Regras do Evento
            </span>
            <QuillEditor theme="snow" :toolbar="toolbarOptions" :options="editorOptions" v-model:content="data.regras"
              id="regras" style="height: 200px;" class="" contentType="html" name="regrasEvento" />
          </label>
        </div>
        <div class="w-full p-2">
          <div class="flex flex-col flex-1 mt-1 mx-3 gap-3 mt-6 items-center">
            <img class="flex-auto rounded-lg shadow-lg w-36 h-36"
              :src="tempImage ? readURL(tempImage) : 'https://via.placeholder.com/400x400'" />
            <button class="
                    bg-primary
                    rounded-md
                    shadow-md               
                    font-bold
                    flex
                    items-center      
                    px-4
                    py-2
                    btnImg
                  ">
              <label class="text-white" for="logo">Adicionar</label>
              <i class="ml-3 text-white gg-camera"></i>
              <input @change="changeImage" class="absolute opacity-0 bton" id="logo" type="file" ref="button"
                name="selectImage" style=" left: -1px; bottom: -35px;" />
            </button>
          </div>
        </div>
      </div>


      <!-- Configuração de Horario De Produtos e Imagem -->
      <!--<div class="my-6 mx-5 flex items-center justify-start">
        <span class="text-sm text-gray-500">Aqui abaixo está a Configuração da parada de venda dos produtos do Evento,
          com
          sua Data e Hora.
        </span>
      </div>

      <div class="flex lg:flex-row flex-col">

        <div class="w-full p-2">
          <Input type="customDate" :placeholder="'Dia/Mês/Ano'" id="data_site" v-model="data.data_site"
            label="Data Site" />
        </div>

        <div class="w-full p-2">
          <Input v-model="data.hora_site" id="horaSite" type="customTime" label="Hora do Site"
            placeholder="Hora de Encerramento" />
        </div>

      </div>

      <div class="flex lg:flex-row flex-col">

        <div class="w-full p-2">
          <Input type="customDate" :placeholder="'Dia/Mês/Ano'" id="data_voucher" v-model="data.data_voucher"
            label="Data do Voucher" />
        </div>

        <div class="w-full p-2">
          <Input v-model="data.hora_voucher" id="horaVoucher" type="customTime" label="Hora do Voucher"
            placeholder="Hora do Voucher" />
        </div>

      </div>

      <div class="flex lg:flex-row flex-col">

        <div class="w-full p-2">
          <Input type="customDate" :placeholder="'Dia/Mês/Ano'" id="data_fisico" v-model="data.data_fisico"
            label="Data do Ingresso Físico" />
        </div>

        <div class="w-full p-2">
          <Input v-model="data.hora_fisico" id="horaFisico" type="customTime" label="Hora do Ingresso Físico"
            placeholder="Hora do Ingresso Físico" />
        </div>

      </div>  

       <div class="block p-1 m-2  h-20">
        <div class="flex-1 my-3">
          <label>
            <input type="checkbox" v-model="data.show_organizador" class="mr-2" />
            Inserir Informações do Organizador no Voucher
          </label>
        </div> 
         <div class="flex-1 my-3">
          <label>
            <input type="checkbox" v-model="integracaoCatraca" class="mr-2" />
            Possui Integração com o Catraca
          </label>
        </div>
      </div>-->
    </div>

    <div v-if="integracaoCatraca && pagina === 1" class="card shadow-sm border p-8 bg-white my-5">
      <div class="flex lg:flex-row flex-col">
        <div class="w-full p-2">
          <Input type="default" v-model="catraca.login" label="Login" placeholder="Login da Integração Catraca" />
        </div>
        <div class="w-full p-2">
          <Input type="default" v-model="catraca.senha" label="Senha da Integração Catraca"
            placeholder="Senha da Integração Catraca" />
        </div>
      </div>
      <div class="flex lg:flex-row flex-col">
        <div class="w-full p-2">
          <Input type="default" v-model="catraca.url_catraca" label="Url da catraca"
            placeholder="URL da Integracao Catraca" />
        </div>
        <div class="w-full p-2">
          <Input v-model="catraca.url_login" type="default" label="URL para Logar na Integracao"
            placeholder="URL para Logar na Integracao (/api/v1/Logon)" />
        </div>
      </div>
      <div class="flex lg:flex-row flex-col">
        <div class="w-full p-2">
          <Input v-model="catraca.nak" type="textarea" label="NAK" placeholder="NAK" />
        </div>
      </div>

      <div class="flex justify-end">
        <Button @click="salvarIntegracao" color="primary" text="Salvar Integração" />
      </div>
    </div>

    <div v-if="pagina === 2">

      <div class="container mx-auto p-4 text-center mt-8">
        <small class="text-gray-500 font-bold">As alterações são salvas automaticamente!</small>
        <div class="flex flex-wrap mt-10">

          <div class="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4 h-44 p-3">
            <h3>Retirar Ingresso</h3>
            <small class="text-gray-600">Deseja ligar ou desligar o sistema?</small>
            <hr class="m-4" />
            <div class="mt-7 flex items-center justify-center">
              <label class="flex items-center relative w-max cursor-pointer select-none">
                <input type="checkbox"
                  class="appearance-none w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500"
                  @change="handleChange('retirar_ingresso', $event)" v-model="retiraIngresso" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4 h-44 p-3">
            <h3>Voucher Planilha</h3>
            <small class="text-gray-600">Deseja ligar ou desligar o sistema?</small>
            <hr class="m-4" />
            <div class="mt-7 flex items-center justify-center">
              <label class="flex items-center relative w-max cursor-pointer select-none">
                <input type="checkbox"
                  class="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500"
                  @change="handleChange('voucher_planilha', $event)" v-model="voucherPlanilha" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>


          <div class="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4 h-44 p-3">
            <h3>Estoque Magento</h3>
            <small class="text-gray-600">Deseja ligar ou desligar o sistema?</small>
            <hr class="m-4" />
            <div class="mt-7 flex items-center justify-center">
              <label class="flex items-center relative w-max cursor-pointer select-none">
                <input type="checkbox"
                  class="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500"
                  @change="handleChange('estoque_magento', $event)" v-model="estoqueMagento" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4 h-44 p-3">
            <h3>Catraca</h3>
            <small class="text-gray-600">Deseja ligar ou desligar o sistema?</small>
            <hr class="m-4 mt-10" />
            <div class="mt-7 flex items-center justify-center">
              <label class="flex items-center relative w-max cursor-pointer select-none">
                <input type="checkbox"
                  class="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500"
                  @change="handleChange('catraca', $event)" v-model="catracaC" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>

          <div class="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4 h-44 p-3">
            <h3>Santa Ficha</h3>
            <small class="text-gray-600">Deseja ligar ou desligar o sistema? <br /> <span class="font-bold"> ID EVENTO:
                {{ eventoId }}</span></small>
            <hr class="m-4" />
            <div class="mt-7 flex items-center justify-center">
              <label class="flex items-center relative w-max cursor-pointer select-none">
                <input type="checkbox"
                  class="appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500"
                  @change="handleChange('santa_ficha', $event)" v-model="santaFicha" />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { GET, PUT, POST } from "../../services/api";
import { inject, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "../../services/validate";
import { dataAtualFormatada, convertToUTC, dateBrtoStamp, } from "@/services/formater";
import moment from 'moment-timezone';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "EditarEvento",
  components: {
    Input,
    Button,
    QuillEditor,
  },
  data() {
    return {
      isMobile: false,
      toolbarOptions: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ],
      editorOptions: {
        modules: {
          autoformat: {
            remove: false,
            rules: [
              { newline: true, format: [] }
            ]
          }
        }
      },
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const route = useRoute();
    const today = ref(dataAtualFormatada("us"));
    const router = useRouter();
    const loader = inject("loading");
    const modalEventoSantaFicha = inject("modalEventoSantaFicha");
    const alert = inject("alert");
    const modal = inject("modal");
    const data = ref({});
    const santaficha = ref({});
    const tempImage = ref();
    const integracaoCatraca = ref(false)
    const pagina = ref(0)
    const eventoId = ref(0)

    const retiraIngresso = ref(false)
    const voucherPlanilha = ref(false)
    const estoqueMagento = ref(false)
    const catracaC = ref(false)
    const santaFicha = ref(false)

    const flagUpdataIntegracaoCatraca = ref(false)
    const catraca = ref({
      "id_evento": route.params.id,
      "login": null,
      "senha": null,
      "nak": null,
      "url_login": null,
      "url_catraca": null,
      "deleted_at": null
    })

    const mudarPoliticaEstoque = async (evento) => {
      try {
        await PUT(`event/${evento.id}`, { 'integracao_magento': evento.estoque ? 0 : 1 })

      } catch (error) {
        alert.open("Erro", "[SC110] Não foi possível alterar a política de estoque, verifique sua conexão com a internet!!", "danger");
      }
    }

    const SantaFicha = async () => {
      modalEventoSantaFicha.open()
    }

    async function ativarEDesativarCatraca() {

      if (catraca.value.id) {

        try {
          loader.open()


          if (catraca.value['deleted_at']) {
            catraca.value['deleted_at'] = null

            await POST('catraca', catraca.value)
            loader.close()
            alert.open(
              "Sucesso!",
              `A integração foi ativada com sucesso!`,
              "success"
            );
          }
          else {

            catraca.value['deleted_at'] = new Date()

            await POST('catraca', catraca.value)
            loader.close()
            alert.open(
              "Sucesso!",
              `A integração foi desativada com sucesso!`,
              "success"
            );
          }

        } catch (error) {
          loader.close()
          alert.open("Atenção!", "[SC111] Não foi possível ativar/desativar a catraca,verifique sua conexão com a internet!!", "warning");
        }



      }


    }

    const getFuncionalidade = async () => {
      loader.open()

      console.log(integracaoCatraca.value)

      if (integracaoCatraca.value === true) {
        console.log('Entrando')
        const data = {
          idEvento: route.params.id,
          type: 'catraca',
          acao: true
        };
        const catraca = await POST(`event/funcionalidade`,
          data
        );   
        catracaC.value = true
      } else {
        const data = {
          idEvento: route.params.id,
          type: 'catraca',
          acao: false,
          catracaNew: true
        };
        const catraca = await POST(`event/funcionalidade`,
          data
        );
        catracaC.value = false
      }

      const response = await GET(`event/funcionalidade/${route.params.id}`);
      response.forEach(item => {
        if (item.type === 'retirar_ingresso' && item.deleted_at === null) {
          retiraIngresso.value = true
        }
        if (item.type === 'estoque_magento' && item.deleted_at === null) {
          voucherPlanilha.value = true
        }
        if (item.type === 'voucher_planilha' && item.deleted_at === null) {
          estoqueMagento.value = true
        }
        if (item.type === 'catraca' && item.deleted_at === null) {
          catracaC.value = true
        }
        if (item.type === 'santa_ficha' && item.deleted_at === null) {
          santaFicha.value = true
        } else {
          santaFicha.value = false
        }
      })
      loader.close()
    }

    const handleModalClosed = () => {
      getFuncionalidade();
    };

    const handleChange = async (type, event) => {
      loader.open()
      if (type === "catraca") {
        integracaoCatraca.value = catracaC.value
      }


      const data = {
        idEvento: route.params.id,
        type: type,
        acao: true
      };

      if (event.target.checked) {
        data.acao = true

        if (type === "estoque_magento") {
          const evento = {
            id: route.params.id,
            estoque: 1
          };
          mudarPoliticaEstoque(evento)
        }

        try {

          if (type === "santa_ficha") {
            SantaFicha()
          } else {

            const response = await POST(`event/funcionalidade`,
              data
            );
            alert.open(
              "Sucesso!",
              `${type} foi ativado com sucesso!`,
              "success"
            );

          }
        } catch (error) {
          const erroPadrão = `[SC112] Não foi possível ativar o sistema, verifique sua conexão com a internet!!`
          if (error !== `TypeError: Cannot read properties of undefined (reading 'status')`)
            alert.open("Sucesso!", error, "danger");
          else
            alert.open("Sucesso!", erroPadrão, "danger");
        }
        loader.close()

      } else {
        data.acao = false
        if (type === "estoque_magento") {
          const evento = {
            id: route.params.id,
            estoque: 0
          };
          mudarPoliticaEstoque(evento)
        }

        try {
          const response = await POST(`event/funcionalidade`,
            data
          );
          alert.open(
            "Sucesso!",
            `${type} foi desativado com sucesso!`,
            "success"
          );
        } catch (error) {
          alert.open(
            "Sucesso!",
            `[SC113] Não foi possível desativar o sistema, verifique sua conexão com a internet!!`,
            "danger"
          );
        }
      }
      loader.close()
    };

    watch(
      () => router.currentRoute.value,
      (currentRoute, previousRoute) => {
        const desiredPath = '/eventos/editar/1122';
        const desiredQuery = { pag: '2' };
        if (
          currentRoute.path === desiredPath &&
          JSON.stringify(currentRoute.query) === JSON.stringify(desiredQuery)
        ) {
          getFuncionalidade()
          const newQuery = { ...router.currentRoute.value.query };
          delete newQuery.pag;
          router.push({ path: currentRoute.path, query: newQuery });
        }
      }
    );


    const setPagina = (pag) => {
      pagina.value = pag

    }

    const readURL = (file) => {
      if (typeof file == "string") {
        return file;
      }
      return window.URL.createObjectURL(file)
    };


    onMounted(async () => {
      loader.open();
      eventoId.value = route.params.id
      try {
        const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))
        const object = { ...(await GET(`event/${route.params.id}`)) };
        const sf = await GET(`evento_santaficha/${route.params.id}`)


        let org, check

        if (object.evento_usuario_perfil) {
          org = object.evento_usuario_perfil.filter(el => el.id_perfil === 1)
          check = object.evento_usuario_perfil.filter(el => el.id_perfil === 5)
        }


        object.organizador = org[0]?.usuario?.usuario ? org[0].usuario.usuario : '';
        object.checkIn = check[0]?.usuario?.usuario ? check[0].usuario.usuario : '';

        santaficha.value = sf



        if (object.data_hora_parada != "31/12/1969 21:00:00") {
          const date = object.data.replace("-", "/").replace("-", "/").split("/").reverse().join('/');
          data.value = {
            ...object,

            "hora_encerramento": object.data_hora_parada ? moment(object.data_hora_parada).tz(usuarioConta.timeZone).format('HH:mm') : "",
            "data_encerramento": object.data_hora_parada ? moment(object.data_hora_parada).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            //inicio
            "data_inicio": object.data_inicio ? moment(object.data_inicio).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_inicio": object.data_inicio ? moment(object.data_inicio).tz(usuarioConta.timeZone).format('HH:mm') : "",
            //fim
            "data_fim": object.data_fim ? moment(object.data_fim).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_fim": object.data_fim ? moment(object.data_fim).tz(usuarioConta.timeZone).format('HH:mm') : "",
            //voucher
            "data_voucher": object.data_voucher ? moment(object.data_voucher).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_voucher": object.data_voucher ? moment(object.data_voucher).tz(usuarioConta.timeZone).format('HH:mm') : "",
            //site
            "data_site": object.data_site ? moment(object.data_site).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_site": object.data_site ? moment(object.data_site).tz(usuarioConta.timeZone).format('HH:mm') : "",
            //fisico
            "data_fisico": object.data_fisico ? moment(object.data_fisico).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_fisico": object.data_fisico ? moment(object.data_fisico).tz(usuarioConta.timeZone).format('HH:mm') : "",
          }

          data.value.data = date
        } else {


          const date = object.data.replace("-", "/").replace("-", "/").split("/").reverse().join('/');

          data.value = {
            ...object,

            "hora_encerramento": object.data_hora_parada ? moment(object.data_hora_parada).tz(usuarioConta.timeZone).format('HH:mm') : "",
            "data_encerramento": object.data_hora_parada ? moment(object.data_hora_parada).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            //inicio
            "data_inicio": object.data_inicio ? moment(object.data_inicio).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_inicio": object.data_inicio ? moment(object.data_inicio).tz(usuarioConta.timeZone).format('HH:mm') : "",
            //fim
            "data_fim": object.data_fim ? moment(object.data_fim).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_fim": object.data_fim ? moment(object.data_fim).tz(usuarioConta.timeZone).format('HH:mm') : "",
            //voucher
            "data_voucher": object.data_voucher ? moment(object.data_voucher).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_voucher": object.data_voucher ? moment(object.data_voucher).tz(usuarioConta.timeZone).format('HH:mm') : "",
            //site
            "data_site": object.data_site ? moment(object.data_site).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_site": object.data_site ? moment(object.data_site).tz(usuarioConta.timeZone).format('HH:mm') : "",
            //fisico
            "data_fisico": object.data_fisico ? moment(object.data_fisico).tz(usuarioConta.timeZone).format('DD/MM/YYYY') : "",
            "hora_fisico": object.data_fisico ? moment(object.data_fisico).tz(usuarioConta.timeZone).format('HH:mm') : "",
          }

          data.value.data = date

        }
        delete object.id;

        // data.value["data"] = (object.data);
        data.value["nome"] = object.nome;

        data.value["taxa_sc"] = object.taxa_sc

        data.value["regras"] = object.regras


        if (object.show_promoters_document)
          data.value["show_organizador"] = true


        tempImage.value = `https://santocartao-files.s3.amazonaws.com/event-pictures/${route.params.id}.jpg`

        const integracao = await GET(`catraca/${route.params.id}`)

        if (integracao && integracao.success) {
          integracaoCatraca.value = integracao.data.deleted_at ? false : true
          catraca.value = { ...integracao.data }
        }

        loader.close();

        setTimeout(() => {
          flagUpdataIntegracaoCatraca.value = true
        }, 1000);

      } catch (error) {
        alert.open("Atenção!", "[SC114] Não foi possível recuperar informações do evento", "warning");
        loader.close();
      }
      getFuncionalidade();

      loader.close();
    });

    const edit = async () => {
      try {
        const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))
        const obj = {
          ...data.value,

          "integracao_magento": data.value['integracao_magento'] ? 1 : 0,
          "show_promoters_document": (data.value["show_organizador"]) ? 1 : 0,

          "data": dateBrtoStamp(data.value['data']),

          "data_hora_parada": convertToUTC(data.value["data_encerramento"], data.value["hora_encerramento"], usuarioConta.timeZone),


          "data_inicio": convertToUTC(data.value["data_inicio"], data.value["hora_inicio"], usuarioConta.timeZone),


          "data_fim": convertToUTC(data.value["data_fim"], data.value["hora_fim"], usuarioConta.timeZone),

          "data_site": convertToUTC(data.value["data_site"], data.value["hora_site"], usuarioConta.timeZone),


          "data_voucher": convertToUTC(data.value["data_voucher"], data.value["hora_voucher"], usuarioConta.timeZone),

          "data_fisico": convertToUTC(data.value["data_fisico"], data.value["hora_fisico"], usuarioConta.timeZone),

        }



        delete obj.hora_encerramento
        delete obj.data_encerramento
        delete obj.organizador
        delete obj.show_organizador
        delete obj.hora_inicio
        delete obj.hora_fim
        delete obj.hora_site
        delete obj.hora_fisico
        delete obj.hora_voucher
        delete obj.evento_usuario_perfil
        delete obj.organizador
        delete obj.checkIn
        delete data.value.organizador
        delete data.value.checkIn


        delete data.value.relatorio;

        if (typeof tempImage.value != "string") {
          obj.imagem = tempImage.value
        }

        if (!obj.regras) {
          delete obj.regras
        }

        const validate = isEmpty(data.value);
        if (validate) {
          alert.open("Atenção!", validate, "warning");
        } else {
          modal.open(
            "Atenção",
            "Deseja realmente editar esse evento?",
            "warning",
            "Sim",
            async () => {
              try {
                loader.open();
                delete obj.id;

                const objRequest = new FormData();
                const item = obj;
                for (const key in item) {
                  objRequest.append(key, item[key]);
                }

                const response = await PUT(
                  `event/${route.params.id}`,
                  objRequest
                );

                alert.open(
                  "Sucesso!",
                  `O evento ${data.value.nome} foi editado com sucesso!`,
                  "success"
                );
                loader.close();
                router.push("/eventos");
              } catch (e) {
                loader.close();
                const erroPadrao = '[SC115] Não foi possível editar o evento, verifique se as campos estão corretos e sua conexão com a internet!!'
                if (e !== `TypeError: Cannot read properties of undefined (reading 'status')`)
                  alert.open("Atenção!", e, "warning");
                else
                  alert.open("Atenção!", erroPadrao, "warning");
              }
            },
            modal.close()
          );

        }

      } catch (error) {
        loader.close();
        alert.open("Atenção!", "[SC116] Não foi possível atualizar as informações de edição do evento, verifique se os campos estão corretos e sua conexão com a internet!! ", "warning");
      }

    };

    const changeImage = (event) => {
      const obj = {
        nome: data.value.nome,
        "taxa_sc": data.value.taxa_sc,
        data: data.value.data,
        "data_encerramento": data.value["data_encerramento"],
        "hora_encerramento": data.value["hora_encerramento"],
        "show_organizador": data.value["show_organizador"],
        //inicio
        "data_inicio": data.value["data_inicio"],
        "hora_inicio": data.value["hora_inicio"],
        //fim
        "data_fim": data.value["data_fim"],
        "hora_fim": data.value["hora_fim"],
        //voucher
        "data_voucher": data.value["data_voucher"],
        "hora_voucher": data.value["hora_voucher"],
        //site
        "data_site": data.value["data_site"],
        "hora_site": data.value["hora_site"],
        //fisico
        "data_fisico": data.value["data_fisico"],
        "hora_fisico": data.value["hora_fisico"],

        regras: data.value.regras,

        //organizador e checkoing
        "checkIn": data.value['checkIn'],
        "organizador": data.value['organizador']
      };
      data.value = obj;
      tempImage.value = event.target.files[0];
    };


    const salvarIntegracao = async () => {
      const validate = isEmpty(catraca.value);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        try {
          loader.open();
          await POST('catraca', catraca.value)

          alert.open(
            "Sucesso!",
            `O evento ${data.value.nome} foi editado com sucesso!`,
            "success"
          );
          loader.close();
          router.push("/eventos");
        } catch (e) {
          loader.close();
          alert.open("Atenção!", "[SC117] Não foi possível salvar a integração com a catraca, verifique se os campos estão corretos e sua conexão com a internet!!", "warning");
        }
      }

    }


    watch(integracaoCatraca, () => {
      if (flagUpdataIntegracaoCatraca.value) {
        ativarEDesativarCatraca()
      }


    })



    return { edit, data, changeImage, readURL, tempImage, SantaFicha, santaficha, integracaoCatraca, catraca, salvarIntegracao, today, pagina, setPagina, handleChange, getFuncionalidade, retiraIngresso, voucherPlanilha, estoqueMagento, catracaC, santaFicha, eventoId, handleModalClosed };
  },
};
</script>

<style scoped>
.btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bton {
  z-index: 5;
  width: 140px;
  height: 75px;
}

input[type="file"]::-webkit-file-upload-button {
  height: 40px !important;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider::before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: transform 0.4s;
}

input:checked+.slider::before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}

.slider {
  background-color: #ccc;
  transition: background-color 0.4s;
}

input:checked+.slider {
  background-color: #7c1d55;
}

/*input[type="radio"] {
  appearance: none;
  height: 15px;
  width: 15px;
  background: #cecdcd;
  border-radius: 30px;
  cursor: pointer;
}

input[type="radio"]:checked {
  height: 15px;
  width: 15px;
  background: #7c1d55;
  border-radius: 30px;
}*/
</style>